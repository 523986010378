import { render, staticRenderFns } from "./PhotoUploadHandler.vue?vue&type=template&id=352e47bc&"
import script from "./PhotoUploadHandler.vue?vue&type=script&lang=ts&"
export * from "./PhotoUploadHandler.vue?vue&type=script&lang=ts&"
import style0 from "./PhotoUploadHandler.vue?vue&type=style&index=0&id=352e47bc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports