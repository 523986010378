export enum TypeFieldEnum {
    GeneralContractor,
    Subcontractor,
    Project,
    Section,
    Deadline,
    TypeOfWork,
    BasicRequirement,
    Description,
    Floor,
    CompliteMessage,
    ComplitePhoto,
    TypeOfRemark,
}
